import axios from "axios";

const API_PROVIDER_LOGIN = (login) => {
    const validateProviderAuth = ['email', 'password'];
    return new Promise((resolve, reject) => {
        validateProviderAuth.forEach(item => {
            if (!login[item]) reject(`Не передається ${item} у запиті!`)
        });
        axios.post('/api/account/auth', login)
            .then(resp => resp.data)
            .then(resp => resolve(resp))
            .catch(err => reject(err.response.data))
    })
}

export const providerAuth = (login) => {
    const errorMessage = {
        LoginNotVerification: 'Ваш акаунт на верифікації, вам прийде лист з повідомленням',
        IncorrectLoginOrPassword: 'Не коректно введено логін або пароль',
        LoginBlocked: 'Ваш акаунт заблокований, зверніться до менеджера'
    }
    return new Promise((resolve, reject) => {
        API_PROVIDER_LOGIN(login)
            .then(resp => resolve(resp))
            .catch(err => {
                if (err.message in errorMessage) return reject(errorMessage[err.message]);
                reject('Невірно введено логін чи пароль');
            })
    });
}