<template>
  <CContainer class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CRow>
        <img src="/img/logo_v2.png" class="w-100 float-left" alt="logo Tender Telecom">
      </CRow>
      <CCol col="12" sm="12" md="12" lg="10" xl="9">
        <CCardGroup>
          <CCard class="p-4">
            <CForm
                :was-validated="wasValidated"
                @submit.prevent="login"
            >
              <h1>Вхід</h1>
              <ul v-if="errors.length >= 1" style="list-style-type: none" class="p-0">
                <li v-for="error in errors" :key="error" data-cy="error">
                  <CAlert style="font-size: 12px;" show color="danger">{{ error }}</CAlert>
                </li>
              </ul>

              <span v-if="!is_login">
                  <CInput
                      required
                      placeholder="Email"
                      prepend="@"
                      data-cy="email"
                      @change="alert_failed"
                      autocomplete="email"
                      @keyup.enter.native="login"
                      v-model="email"
                  >
                  </CInput>
                  <CInput
                      required
                      placeholder="Пароль"
                      @change="alert_failed"
                      data-cy="password"
                      :type="passwordFieldIdType"
                      autocomplete="curent-password"
                      @keyup.enter.native="login"
                      v-model="password"
                  >
                      <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                      <template #append-content>
                          <a @click="showPass" data-cy="showPassword" v-c-tooltip="{
                                    content: 'Показати/сховати пароль',
                                    placement: 'top'
                                }"><CIcon name="cil-low-vision"/></a>
                      </template>
                  </CInput>
                  <CRow>
                    <CCol col="12">
                      <small
                          :class="{
                        'errorCheckbox': $v.acceptRules.$error
                      }">
                        <CInputCheckbox
                            class="ml-1"
                            name="acceptRules"
                            aria-required="false"
                            required
                            data-cy="confirmConditions"
                            @change="$v.acceptRules.$touch()"
                            v-bind:checked.sync="acceptRules"
                        />
                        <label
                            class="ml-4 mt-1"
                            :class="{
                              'errorText': !$v.acceptRules.required || !$v.acceptRules.sameAs
                            }"
                        >
                          <span
                              @click="acceptRules=!acceptRules"
                          >Підтверджую згоду з </span>
                          <a class="conditions-tender" @click="goToFAQ">Умовами та правилами участі</a>
                          в міні-тендері
                        </label>
                      </small>
                    </CCol>
                   </CRow>
              </span>
              <span v-if="is_login">
                <CRow v-if="is_login" class="mt-4 mb-5">
                  <CCol>
                    <vue-recaptcha ref="recaptcha"
                                   @verify="onVerify"
                                   sitekey="6LdbCKgZAAAAAAogKDYhUo5ksCGk_uSDHge2OeML"
                                   :loadRecaptchaScript="true"
                    >
                    </vue-recaptcha>
                  </CCol>
                </CRow>
              </span>

              <CRow class="mt-2">
                <CCol col="12" sm="12" md="6" lg="7" xl="7">
                  <CButton
                      to="/reset"
                      color="link"
                      class="px-0">Забули пароль?
                  </CButton>
                </CCol>
                <CCol col="12" sm="12" md="6" lg="5" xl="5" class="text-right">
                  <CButton
                      v-if="loading"
                      color="primary"
                      class="btn-block "
                      disabled

                  >
                    <div class="  align-items-center">
                      <CSpinner color="default" size="sm"/>
                    </div>
                  </CButton>
                  <CButton
                      v-else
                      color="primary"
                      @click="login"
                      data-cy="login"
                      class="px-4 btn-block"
                  >
                    Увійти
                  </CButton>
                </CCol>
              </CRow>
            </CForm>
            <hr class="mb-2">

            <CButton
                @click="loginPrivat"
                data-cy="loginForBankUsers"
                class="btn btn-outline-success mx-4">Вхід для працівників банку
            </CButton>
          </CCard>
          <CCard
              col="12" sm="12" md="10" lg="7" xl="7"
              color="primary"
              text-color="white"
              class="text-center pt-2 px-3"
              body-wrapper
          >
            <h1 class="text-left">Реєстрація</h1>
            <p class="mt-4 text-justify">Портал "ТендерТелеком" призначений для забезпечення потреби АТ КБ”ПРИВАТБАНК” в
              каналах зв'язку. Реєструйтеся та подавайте свої комерційні пропозиції.</p>
            <CButton
                data-cy="registration"
                color="primary-dark"
                class="font-weight-bold"
                to="/register"
            >
              Зареєструватися
            </CButton>
          </CCard>
        </CCardGroup>
        <CRow class="justify-content-center mt-3">
          <h5 class="mt-3">
            <CLink target="_blank" to="/faq">Інформація про сервіс</CLink>
          </h5>
          <h5 class=" ml-3 mt-3">
            <CLink target="_blank" to="/feedback">Зворотній зв'язок</CLink>
          </h5>
          <h5 class=" ml-3 mt-3">
            <CLink
                target="_blank"
                :to="`/faq?q=${ encodeURIComponent('Умови та правила участі в міні-тендері')}`"
            >Умовами та правилами
            </CLink>
          </h5>
        </CRow>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import axios from "axios";
import {email, required, minLength, sameAs} from 'vuelidate/lib/validators';
import VueRecaptcha from 'vue-recaptcha';
import {setLocalCookies} from "@/utils/cookiesUtils";
import {providerAuth} from "@/api/auth";

export default {
  name: 'Login',
  data() {
    return {
      wasValidated: false,
      is_login: false,
      passwordFieldIdType: 'password',
      acceptRules: false,
      robot: false,
      email: "",
      password: "",
      loading: false,
      errors: [],
    }
  },
  /**
   * Добавляємо кастомну валідацію полів
   */
  validations: {
    email: {
      required,
      email,
      minLength: minLength(5)
    },
    password: {
      required,
      minLength: minLength(5)
    },
    acceptRules: {
      required,
      sameAs: sameAs(() => true)
    }
  },
  components: {VueRecaptcha},
  methods: {
    goToFAQ() {
      let quar = encodeURIComponent('Умови та правила участі в міні-тендері');
      let url = `/faq?q=${quar}`
      window.open(url, '_blank')
      // this.$router.push({path: url})
    },
    /**
     * Функція яка показує скритий пароль, який вів користувач
     * @model passwordFieldIdType - змінює тип input
     * @type {string}
     */
    showPass() {
      this.passwordFieldIdType = this.passwordFieldIdType === 'password' ? 'text' : 'password';
    },
    alert_failed() {
      this.failed = false;
    },
    login() {
      this.errors = [];
      this.$v.$touch()
      if (this.$v.$invalid) {
        /** checking the validation */
        this.errors.push("Заповніть усі поля");
        /** push error and show them*/
        this.wasValidated = true;
        return;
      }
      this.is_login = true; /** */
      if (process.env.VUE_APP_CYPRESS_TT !== "test") {
        if (!this.robot) {
          return;
        }
      }
      this.loading = true;
      let self = this;
      if (!this.acceptRules) return;

      providerAuth({email: this.email, password: this.password})
          .then(() => {
            self.is_login = false;
            self.loading = false;

            self.$store.commit('updateAuthorized', true);
            setLocalCookies('tender_telecom_authorized', self.$store.state.authorized);

            let next = new URLSearchParams(window.location.search).get("next");
            if (!next) {
              next = "/provider";
            }
            self.$router.push({path: next});
          })
          .catch((err) => {
            self.is_login = false;
            self.loading = false;
            self.robot = false;
            self.errors.push(err);
          })
    },
    onVerify() {
      this.robot = true;
      this.login();
    },
    loginPrivat() {
      this.$store.commit('updateAuthorized', true);
      setLocalCookies('tender_telecom_authorized', this.$store.state.authorized);

      let next = new URLSearchParams(window.location.search).get("next");
      if (!next) {
        next = "?next=/v2/";
      } else {
        next = '?next=' + '/v2' + next;
      }

      location.href = "/api/account/auth/privat" + next
    },
    checkLoginError() {
      let errCode = new URLSearchParams(window.location.search).get('error')
      if (!errCode) {
        return;
      }

      switch (errCode) {
        case 'no_roles':
          // this.no_roles_message='У вас не має жодної ролі для доступу цього комплексу';
          this.errors.push('У вас немає жодної ролі для доступу до цього комплексу');
          // this.no_roles=true;
          console.error('No roles')
          break;
        default:
          // this.no_roles=true;
          // this.no_roles_message='Відбулася помилка авторизації';
          this.errors.push('Відбулася помилка авторизації');
          break;
      }
    }
  },
  created() {
    this.checkLoginError();
  },
  mounted() {
    this.email = localStorage.getItem('tender_telecom_mail');
    /**look in the local storage, check if there is already saved email and write in the input field */
    this.$store.commit('updateAuthorized', false);
  }
}
</script>
<style lang="scss" scoped>
.errorCheckbox {
  .errorText {
    color: #cb203d !important;
  }
}
</style>
